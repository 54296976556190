$red-color: #A11717;
$dark-color: #00283D;
$shadow: 2px 2px 5px rgba(1, 28, 42, 0.25);

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  line-height: 16px;
  background: $dark-color;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (min-width: 768px) {
  .reveal {
    visibility: hidden;
  }
}

.h1, h1 {
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  text-shadow: $shadow;
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 64px;
    text-transform: uppercase;
  }
}

.h2, h2 {
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 53px;
  }
}

.h3, h3 {
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
}

.h4, h4 {
  font-family: PT Serif, serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

.h5, h5 {
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

small, .small {
  font-size: 13px;
  line-height: 15px;
  font-weight: inherit;
}

ul {
  padding-left: 16px;
}

.text-red {
  color: $red-color;
}

.text-blue {
  color: $dark-color;
}

.bg-grey {
  background: #f5f5f5;
}

.alert {
  border-radius: 0;
}

.alert-danger {
  color: #ffffff;
  background-color: $red-color;
  border-color: $red-color;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.link {
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-decoration: underline;
  color: $dark-color;

  &:hover {
    color: $dark-color;
    text-decoration: none;
  }
}

header {
  position: absolute;
  padding-top: 20px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.header-mobile {
  background: transparentize($dark-color, 0.2);
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  height: 50px;
  padding: 5px 0;
  box-shadow: $shadow;

  .btn-menu {
    padding: 6px 12px;
  }

  .fa-phone {
    font-size: 24px;
  }
}

.mobile-menu {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  background: transparentize($dark-color, 0.2);
  z-index: 99;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease-in, visibility .3s ease-in;
  @media (min-width: 992px) {
    display: none;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &-wrapper {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.header-fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 10px 0;
  top: -220px;
  background: $dark-color;
  box-shadow: $shadow;

  .header-contact {
    font-family: PT Serif, serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
}

.header-contact {
  color: #ffffff;

  &__icon {
    position: relative;
    top: -1px;
  }

  &_address {
    position: relative;
    padding-left: 16px;

    .header-contact__icon {
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
}

a.header-contact:hover {
  color: $red-color;
}

.language {
  &__item {
    font-family: PT Serif, serif;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    padding: 6px 8px;
    border: 1px solid #FFFFFF;
    background: transparent;

    &:hover,
    &.active {
      background: rgba(255, 255, 255, 0.3);
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.menu {
  .nav-link {
    font-family: PT Serif, serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .nav-item .nav-link {
    padding: 4px 0;
  }

  .nav-item:not(:last-of-type) .nav-link {
    margin-right: 30px;
  }

  .nav-item .nav-link {
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      content: '.';
      color: transparent;
      background: #ffffff;
      height: 1px;
      transition: all .5s;
    }

    &:hover:after {
      width: 100%;
    }
  }
}

.btn {
  box-shadow: $shadow;
  background: $red-color;
  padding: 6px 18px;
  color: #ffffff;
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  border-radius: 0;
  border: 1px solid $red-color;
  position: relative;
  overflow: hidden;

  &:hover {
    color: #ffffff;
  }

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    right: 0;
    transform: translate(0, -50%) scale(1);
    display: block;
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    transition: 0.5s;
    z-index: 1;
  }

  &:hover {
    &:before {
      transform: scale(8.5);
    }
  }
}

@keyframes scale {
  //0% {
  //  transform: scale(1.5);
  //}
  //100% {
  //  transform: scale(1);
  //}
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.video-section {
  position: relative;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  min-height: 600px;
  @media (min-width: 768px) {
    height: 100vh;
    min-height: 768px;
  }

  &__video {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    z-index: 0;
  }

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(90deg, $dark-color 13%, rgba(0, 40, 61, 0.600956) 48%, rgba(0, 40, 61, 0) 100%), url('../img/about-bg.jpg') no-repeat top center;
    background-blend-mode: multiply, normal;
    background-size: cover;
    animation: scale 2s forwards;
  }
}

@mixin bg-truck() {
  background: linear-gradient(0deg, rgba(0, 40, 61, 0.7), rgba(0, 40, 61, 0.7)), url('../img/about-bg_mobile.jpg') no-repeat top center;
  background-blend-mode: multiply, normal;
  background-size: cover;
  @media (min-width: 768px) {
    background: linear-gradient(90deg, $dark-color 13%, rgba(0, 40, 61, 0.600956) 48%, rgba(0, 40, 61, 0) 100%), url('../img/about-bg.jpg') no-repeat top center fixed;
    background-size: cover;
  }
  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (min-resolution: 120dpi) {
    background: linear-gradient(90deg, $dark-color 13%, rgba(0, 40, 61, 0.600956) 48%, rgba(0, 40, 61, 0) 100%), url('../img/about-bg_big.jpg') no-repeat top center fixed;
    background-size: cover;
  }
}

@mixin bg-vegetables() {
  background: linear-gradient(0deg, rgba(0, 40, 61, 0.7), rgba(0, 40, 61, 0.7)), url('../img/assortment-bg_mobile.jpg') no-repeat top center;
  background-blend-mode: multiply, normal;
  background-size: cover;
  @media (min-width: 768px) {
    background: linear-gradient(90deg, $dark-color 13%, rgba(0, 40, 61, 0.600956) 48%, rgba(0, 40, 61, 0) 100%), url('../img/assortment-bg.jpg') no-repeat top center fixed;
    background-size: cover;
  }
  @media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 768px) and (min-resolution: 120dpi) {
    background: linear-gradient(90deg, $dark-color 13%, rgba(0, 40, 61, 0.600956) 48%, rgba(0, 40, 61, 0) 100%), url('../img/assortment-bg_big.jpg') no-repeat top center fixed;
    background-size: cover;
  }
}

.video-section-wrapper {
  position: relative;
  z-index: 5;
  background: linear-gradient(0deg, rgba(0, 40, 61, 0.7), rgba(0, 40, 61, 0.7)), url('../img/bg-mobile.jpg') no-repeat top center;
  background-size: cover;
  background-blend-mode: multiply, normal;
  padding-top: 120px;
  height: 100vh;
  min-height: 600px;
  @media (min-width: 768px) {
    height: 100vh;
    min-height: 768px;
    padding-top: 171px;
    background: none;
  }

  &_about {
    @include bg-truck();
  }

  &_assortment {
    @include bg-vegetables();
  }
}

.video-section-content {
  margin-bottom: 70px;
}

.bordered-block {
  border-left: 1px solid #ffffff;
  padding-left: 30px;
  @media (min-width: 768px) {
    padding-left: 70px;
  }

  &_dark {
    border-color: $dark-color;
  }
}

.section {
  &_quality {
    padding-top: 90px;
    box-shadow: $shadow;
    position: relative;
    z-index: 1;
  }

  &_form {
    position: relative;
    @include bg-truck();
  }

  &_form-assortment {
    position: relative;
    @include bg-vegetables();
  }

  &_assortment {
    position: relative;
    margin-bottom: 50px;
    @media (min-width: 992px) {
      &:before {
        content: '';
        height: 385px;
        bottom: -50px;
        width: 50%;
        right: 0;
        background: $red-color;
        position: absolute;
        z-index: 0;
        @media (min-width: 1200px) {
          height: 470px;
        }
      }
    }
  }

  &_logistics {
    background: #F5F5F5;
    position: relative;
    padding-top: 70px;
    @media (max-width: 991px) {
      .text-blue {
        color: #ffffff;
      }
    }

    &:before {
      content: '';
      height: calc(100% - 105px);
      top: 0;
      width: 100%;
      left: 0;
      background: $red-color;
      position: absolute;
      z-index: 0;
      @media (min-width: 992px) {
        height: calc(100% - 234px);
        width: 50%;
      }
      @media (min-width: 1200px) {
        height: calc(100% - 279px);
      }
    }
  }

  &_steps {
    background: #F5F5F5;
    position: relative;
    overflow: hidden;

    .number {
      -webkit-text-stroke: 1px $dark-color;
    }
  }

  &_categories {
    position: relative;

    .container {
      z-index: 1;
    }

    &:before {
      content: '';
      height: 395px;
      top: 0;
      width: 100%;
      left: 0;
      background: $red-color;
      position: absolute;
      z-index: 0;
      @media (min-width: 992px) {
        width: calc((100% - 1140px) / 2 + 1140px / 12 * 8 - 15px);
      }
    }

    &:after {
      content: '';
      height: 338px;
      bottom: 0;
      width: 100%;
      right: 0;
      background: $red-color;
      position: absolute;
      z-index: 0;
      @media (min-width: 992px) {
        width: calc((100% - 1140px) / 2 + 1140px / 12 * 8 - 15px);
      }
    }

    .bordered-block {
      margin: 44px 0 45px;
    }

    @media (max-width: 991px) {
      .bordered-block_fix {
        padding-left: 0;
      }
    }
  }
}

.sku-wrapper {
  padding: 55px 0 0 0;
  position: relative;
  @media (min-width: 768px) {
    padding: 55px 0;
  }

  &:before {
    content: '';
    height: 100%;
    top: 0;
    width: 100%;
    left: 0;
    background: $red-color;
    position: absolute;
    @media (min-width: 992px) {
      width: 66%;
    }
  }
}

.sku-text {
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 576px) {
    padding-left: 70px;
    padding-right: 0;
  }
}

.sku-img-wrapper {
  padding: 15px;
  @media (min-width: 992px) {
    border: 1px solid #FFFFFF;
    padding: 34px;
  }

  img {
    box-shadow: $shadow;
  }
}

.number {
  font-family: PT Serif, serif;
  font-weight: bold;
  font-size: 90px;
  line-height: 119px;
  color: transparent;
  -webkit-text-stroke: 1px #ffffff;

  &_sku {
    position: absolute;
    top: -70px;
    left: 70%;
  }
}

.products-wrapper {
  position: relative;

  .container {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    height: calc(50% + 50px);
    background: $red-color;
    bottom: -50px;
    right: 50%;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 50%;
    height: calc(50% + 50px);
    background: $red-color;
    top: -50px;
    left: 50%;
    right: 0;
    z-index: 1;
  }
}

.products-text {
  padding: 30px 15px;
  color: $dark-color;
  @media (min-width: 992px) {
    min-height: 232px;
  }
  @media (min-width: 1200px) {
    padding: 64px 20px 15px;
    min-height: 277px;
  }
}

@media (max-width: 767px) {
  .optimization-text {
    border-left: 1px solid $red-color;
    padding: 5px 0 5px 15px;
    margin-bottom: 0;
    margin-left: 28px;
  }
}

@media (min-width: 768px) {
  .optimization-bordered-right {
    border-right: 1px solid $red-color;
    padding-left: 80px;
  }
  .optimization-bordered {
    padding-left: 80px;
    border-top: 1px solid $red-color;
    border-left: 1px solid $red-color;
  }
  .optimization-icon-wrapper {
    padding: 0 15px;
    background: $dark-color;
    position: absolute;
    top: -28px;
    left: 0;
  }
}

.icon-circle {
  width: 57px;
  height: 57px;
  background: $red-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $shadow;
}

.icon-line {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border-bottom: 1px solid #ffffff;
    width: calc(100% - 35px);
    right: 0;
    bottom: 0;
  }
}

.quality-wrapper {
  background: $red-color;
}

.quality-img {
  box-shadow: $shadow;
  transform: translateY(-30px);
  @media (min-width: 576px) {
    transform: translateY(-60px);
  }
}

footer {
  background: $dark-color;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.footer-contact {
  color: #ffffff;

  &__icon {
    position: relative;
    top: -1px;
  }

  a {
    color: #ffffff;

    &:hover {
      color: $red-color;
    }
  }

  &_address {
    position: relative;
    padding-left: 16px;

    .footer-contact__icon {
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
}

.form-control {
  background: transparent;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #ffffff;
  padding: 0 15px 15px 0;
  height: auto;
  color: #FFFFFF;
  font-size: 16px;
  border-radius: 0;

  &:focus {
    background: transparent;
    color: #FFFFFF;
    border-color: #ffffff;
  }
}

.form-control::-webkit-input-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.form-control::-moz-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.form-control:-moz-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.form-control:-ms-input-placeholder {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

.form-control:focus::-webkit-input-placeholder {
  opacity: 0;
}

.form-control:focus::-moz-placeholder {
  opacity: 0;
}

.form-control:focus:-moz-placeholder {
  opacity: 0;
}

.form-control:focus:-ms-input-placeholder {
  opacity: 0;
}

.custom-control {
  padding-left: 30px;
}

.custom-control-label {
  font-size: 12px;
  line-height: 21px;
  display: block;

  &:before {
    position: absolute;
    top: 0;
    width: 21px;
    height: 21px;
    background-color: transparent;
    border: #FFFFFF solid 1px;
    border-radius: 0 !important;
    left: -30px;
  }

  &:after {
    background-size: unset;
    width: 30px;
    height: 30px;
    top: -7px;
    left: -30px;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M4.5 13.5L9.5 20.5L23.5 1' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.0931396' y='0.708374' width='27.813' height='28.6504' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #FFFFFF;
  background-color: transparent;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: unset;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: transparent;
  border-color: #FFFFFF;
}

.assortment-wrapper {
  padding-top: 48px;
  @media (min-width: 992px) {
    padding: 118px 0 0;
  }
}

.icon-logistics {
  fill: #ffffff;
  @media (min-width: 992px) {
    fill: $dark-color;
  }
}

.square-block {
  box-shadow: $shadow;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $red-color;
  display: inline-block;
}

.big-circle {
  background: #f5f5f5;
  border-radius: 50%;
  color: #fff;
  padding: 26px;
  position: absolute;
  bottom: -112px;
  right: -113px;
}

.steps-top {
  position: relative;
  @media (min-width: 992px) {
    border-top: 1px solid $red-color;
    border-right: 1px solid $red-color;
    border-bottom: 1px solid $red-color;
    margin-top: 130px;
    min-height: 273px;
  }
}

@media (min-width: 992px) {
  .steps-wrapper {
    padding-right: 130px;
  }
  .steps-title-block {
    position: absolute;
    max-width: 530px;
    bottom: -69px;
    left: -1px;
  }
  .steps-title-text {
    background: #f5f5f5;
    padding: 15px 0;
    margin: 0;
    z-index: 4;
    position: relative;
  }
}


.steps-bottom {
  position: relative;
  @media (min-width: 992px) {
    border-left: 1px solid $red-color;
    border-bottom: 1px solid $red-color;
    min-height: 273px;
    margin-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .steps-top-content {
    transform: translateY(-130px);
  }
  .steps-bottom-content {
    transform: translateY(144px);
  }
}

.category-block {
  height: 254px;
  box-shadow: $shadow;
  background-size: cover;

  &_vertical {
    height: 100%;
    min-height: 254px;
  }

  @media (min-width: 768px) {
    &:hover {
      .category-block-content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.category-block-wrapper {
  margin-bottom: 30px;
}

.category-block-content {
  background: linear-gradient(90deg, $dark-color 12.47%, rgba(0, 40, 61, 0) 100%);
  height: 100%;
  padding: 15px;
  transition: 0.3s;
  @media (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
    padding: 15px 15px 15px 40px;
  }
}
